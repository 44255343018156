<template>
  <div>
    <header>
      <el-form ref="form" :model="form" label-width="100px" :inline="true">
          <el-form-item label="选择代理：">
            <el-cascader
              :props="{'value':'unitNo','label':'name'}"
              clearable
              v-model="unitNos"
              :options="agentList"
              style="width: 300px"
              placeholder="请选择代理商"
            >
            </el-cascader>
            <!-- <AgentTree  :props="props" v-model="unitNos" style="width: 300px"></AgentTree> -->
          </el-form-item>
          <el-form-item label="日期：">
               <el-date-picker
                  v-model="timeArr"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="timestamp"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
          </el-form-item>
        <el-button @click="getCollect" type="primary ml32">查询</el-button>
      </el-form>
    </header>
    <section class="mb30">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="unitName" label="代理商名称" width="180"/>
        <el-table-column prop="orderMoney" label="代理商销售额(含保险)" />
        <el-table-column prop="myIncome" label="我的收益"/>
        <el-table-column prop="unitIncome" label="代理商收益"/>
        <el-table-column prop="storeIncome" label="门店收益"/>
        <el-table-column prop="beginTime,endTime" label="日期范围">
          <template slot-scope="scope">
            {{scope.row.beginTime | timeToDate}}-{{scope.row.endTime | timeToDate}}
          </template>
        </el-table-column>
      </el-table>
    </section>
    <footer class="fe df">
      <el-pagination
        :page-size="20"
        layout="total, prev, pager, next"
        @current-change="currentChange"
        :total="total"/>
    </footer>
  </div>
</template>
<script>
import { queryHaiLiUnitTree } from "@/api/common";

  import { getCollect } from '@/api/dataStatement'
import  AgentTree from '@/components/AgentTree';
export default {
  data() {
    return {
      agentList:[],
      unitNos:[],
      timeArr:[],
      form:{
        unitNo:'',
        beginTime:'',
        endTime:'',
        pageSize:20,
        beginIndex:0
      },
      selectValue:[],
      total:1,
      tableData: [],
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.getCollect()
      }
    };
    this.getDefaultTime()
    this.getCollect();
    this.queryHaiLiUnitTree();
  },
  methods: {
    //获取默认查询时间
    getDefaultTime() {
      const now = new Date();
      const yesterday = new Date()
      yesterday.setTime(yesterday.getTime() - 3600 * 1000 * 24)
      const currentUse = now
      const year = currentUse.getFullYear(); //得到年份
      const month = currentUse.getMonth();//得到月份
      const date = currentUse.getDate();//得到日期
      const start = new Date(year,month,date,0, 0, 0).getTime()
      const end = new Date(year,month,date,23, 59, 59).getTime()
      this.timeArr = [start,end]
    },
    currentChange(index){
       this.form.beginIndex = index - 1;
       this.getCollect();
    },
    // 获取代理商
    async queryHaiLiUnitTree() {
      const params = {
        isContainFunder: 2,
        // ...this.extra,
      };
      const res = await queryHaiLiUnitTree(params);
      this.agentList = res.ldata;
      // if (res.mdata.flag) {
      //   this.agentList =  handleTree(res.mdata.haiLiUnit.subUnit);
      // } else {
      //   this.agentList = handleTree(res.ldata);
      // }
    },
    // 列表
    async getCollect(){
      if(this.timeArr && this.timeArr.length !== 0){
        this.form.beginTime = this.timeArr[0];
        this.form.endTime = this.timeArr[1];
      }else{
        let year = new Date().getFullYear();
        let mon = new Date().getMonth() + 1;
        let day = new Date().getDate();
        let time = `${year}/${mon}/${day}`;
        let endTime = new Date(time).getTime();
        let beginTime = endTime - 1000*60*60*24;
        this.form.beginTime = beginTime;
        this.form.endTime = endTime;
      }
      this.form.unitNo = this.unitNos[this.unitNos.length - 1];
      let params = {...this.form};
      try{
        const res = await getCollect(params);
        this.total = res.mdata.total;
        this.tableData = res.mdata.leaseIncomeListDtos;
      }catch(e){
        console.log(e,'e');
      }
    }
  },
  components: {
    AgentTree,
  }
};
</script>

<style scoped></style>
